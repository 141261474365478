import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import CloseIcon from '../../images/icons/close.inline.svg';
import 'twin.macro';
import { motion, AnimatePresence } from 'framer-motion';
import { hasGdprCookie, setGdprCookie } from '../../utils/cookie-utils';

const CookieBanner = () => {
  const [show, setShow] = useState(false);
  const setCookie = () => {
    setGdprCookie();
    setShow(false);
  };
  useEffect(() => {
    if (!hasGdprCookie()) {
      setShow(true);
    }
  }, []);
  return (
    <div tw="fixed bottom-0 right-0 p-4 z-50">
      <AnimatePresence>
        {show && (
          <motion.div
            tw="px-6 pt-6 pb-4 bg-white text-brand-purple-800 border-brand-purple-200 border rounded-md shadow-xl relative max-w-md"
            initial={{ y: '100%' }}
            animate={{ y: 0 }}
            exit={{ opacity: 0 }}
          >
            <p tw="text-xs mr-2 lg:text-sm">
              This website stores data such as cookies to enable important site
              functionality including analytics.{' '}
            </p>
            <div tw="flex items-center justify-end font-bold text-sm text-right w-full">
              <Link tw="mr-4" to="/privacy-and-cookies">
                Read our privacy policy
              </Link>
              <button onClick={setCookie}>Close</button>
            </div>
            <button onClick={setCookie} tw="absolute top-0 right-0 p-1">
              <CloseIcon tw="w-5 h-5 block" />
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CookieBanner;
